<template>
    <div>
        <!-- logo -->
        <!-- main -->
        <div class="main">
                <el-main>
                <el-header height="10px">
                    <h2>解决方案</h2>
                </el-header>
                <el-divider ></el-divider>
                <div style=" margin-bottom: 60px;" v-for="(item,index) in data" :key="index">
                    <img :src="url+item.cover" class="img" alt="" @click="go(item.id)">
                    <h2>{{item.articleTitle}}</h2>
                    <div>{{item.gmtCreate | formatDate}}</div>
                    <el-button type="text" @click="go(item.id)">了解更多</el-button>
                    <!-- <div v-html="item.articleContext"></div> -->
                 </div>
                </el-main>
            <!-- <Main /> -->
        </div>
        <!-- 这里是页脚 -->
        <Footer />
    </div>
</template>

<script>
import {get} from "../network/request";
import {BASE_URL} from '../network/config/index'
import {formatDate} from '../network/cldate.js'
import Header from '../components/hearder.vue'
import Footer from '../components/footer.vue'
// import Input from './input.vue'
export default {
    data(){
        return {
            url:BASE_URL+'/',
            data:[],
            data1:[],
            id:''
        }
    },
    filters:{
      formatDate(time) {
        time = time * 1000
        let date = new Date(time)
        // console.log(new Date(time))
        return formatDate(date, 'yyyy-MM-dd hh:mm')
      }
    },
    created(){
        // this.getarticle()
        
        this.id = this.$route.params.id
        console.log(this.id);
    },
    mounted(){
        this.getlist(this.id)
    },
    methods: {
        go(id) {
             this.$router.push({path:'/details',query:{id:id}})
        },
        getlist(id){
            get('/ess/ess-article/getAllArticle/' + id + '/1/5',{
            
            }).then(res=>{
            console.log(res)
            if(res.data.code == 200)
            {
                this.data = res.data.data.data
                // for(const i of res.data.data.data){
                //     // console.log(i.id);
                //     get('/ess/ess-article/get/' + i.id,{

                //     }).then(res=>{
                //         // console.log(res.data.data);
                //         this.data.push(res.data.data)
                //     })
                // }
            }
            }).catch(err=>{
            console.log(err)
            })
        },
    },
    components:{
        Header,
        Footer,
    }
}
</script>

<style lang="less" scoped>
.main{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-left: 30%;
}
.img{
    width: 750px;
    height: 250px;
}

</style>