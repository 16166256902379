import { render, staticRenderFns } from "./Solve.vue?vue&type=template&id=362e7819&scoped=true&"
import script from "./Solve.vue?vue&type=script&lang=js&"
export * from "./Solve.vue?vue&type=script&lang=js&"
import style0 from "./Solve.vue?vue&type=style&index=0&id=362e7819&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "362e7819",
  null
  
)

export default component.exports